.modal-header-wrapper {
  .title-text {
    font-weight: 600;
    font-size: 19px;
  }

  .subtitle-text {
    font-size: 14px;
  }
}

.modal-body {
  padding: 40px;
}

.modal-footer {
  padding: 15px 40px;
  border: none;
}

.modal-backdrop {
  background-color: white;
  &.show {
    opacity: 0.7;
  }

  &.backdrop-black {
    background-color: black;
  }
}

.modal {
  div.modal-dialog {
    &.modal-shadow {
      & > .modal-content {
        box-shadow: 0 0 6px 2px rgba(112, 112, 112, 0.30);
      }
    }

    &.msd-ug-alert-popup {
      .modal-content {
        border: unset;
        margin-top: 100px;
      }
    }
  }
}

.modal-max {
  max-width: calc(100vw - 60px);
}
