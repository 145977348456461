// @mixin center {
//   position: relative;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }

// @mixin center-horizontal {
//   position: relative;
//   left: 50%;
//   transform: translateX(-50%);
// }

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.flex-center {
  @include flex-center();
}
