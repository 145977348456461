@import 'colors.scss';

input, select {
  border-radius: 8px;
  background-color: $grey6;
  border-color: $grey4;
}

textarea {
  border-radius: 8px;
  background-color: $grey6;
  border-color: $grey4 !important;
}
textarea.is-invalid {
  border-color: #dc3545 !important;
}

.form-field {
  &.required {
    label::after {
      content: '*';
      color: $red;
      margin-left: 3px;
    }
  }
}

.form-row-wrapper {
  display: flex;

  .input-row {
    flex: 1;
    padding: 0 10px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
