@import "colors.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.card-grey {
  background-color: $grey6;
  border-radius: 0.625rem;
  border: 0;
}

.accordion {
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-transform: rotate(-180deg);

  & > .panel {
    .card.comment-accordion {
      background-color: $grey5;
      border-radius: 6px;
      border: 1px solid $grey4;

      & > div.card-header {
        border-bottom: unset;

        & > .panel-title {
          & > .accordion-toggle {
            & > button {
              display: flex;
              width: 100%;
              color: $grey1;
              font-size: 12px;
              text-decoration: none;
              text-transform: uppercase;

              &:focus {
                box-shadow: unset;
              }

              &:after {
                flex-shrink: 0;
                width: var(--bs-accordion-btn-icon-width);
                height: var(--bs-accordion-btn-icon-width);
                margin-left: auto;
                content: "";
                background-image: var(--bs-accordion-btn-icon);
                background-repeat: no-repeat;
                background-size: var(--bs-accordion-btn-icon-width);
                transition: var(--bs-accordion-btn-icon-transition);
              }

            }
          }
        }

        &:hover {
          & > .panel-title {
            & > .accordion-toggle {
              & > button {
                color: $blue;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .card.trackers-accordion {
      background-color: white;
      border-radius: 6px;
      border: 1px solid $grey4;

      & > div.card-header {
        border-bottom: unset;
        padding: 0;

        & > .panel-title {
          & > .accordion-toggle {
            & > button {
              display: flex;
              width: 100%;
              color: $blue;
              font-size: 13px;
              font-weight: 500;
              text-decoration: none;
              text-transform: uppercase;
              padding: 0.375rem 0.75rem !important;

              &:focus {
                box-shadow: unset;
              }

              &:after {
                flex-shrink: 0;
                width: var(--bs-accordion-btn-icon-width);
                height: var(--bs-accordion-btn-icon-width);
                margin-left: auto;
                content: "";
                background-image: var(--bs-accordion-btn-icon);
                background-repeat: no-repeat;
                background-size: var(--bs-accordion-btn-icon-width);
                transition: var(--bs-accordion-btn-icon-transition);
              }

            }
          }
        }

        &:hover {
          & > .panel-title {
            & > .accordion-toggle {
              & > button {
                font-weight: 700;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &.panel-open {
      .card.comment-accordion {
        & > div.card-header {
          & > .panel-title {
            & > .accordion-toggle {
              & > button {
                &:after {
                  background-image: var(--bs-accordion-btn-active-icon);
                  transform: var(--bs-accordion-btn-icon-transform);
                }
              }
            }
          }
        }
      }

      .card.trackers-accordion {
        & > div.card-header {
          & > .panel-title {
            & > .accordion-toggle {
              & > button {
                &:after {
                  background-image: var(--bs-accordion-btn-active-icon);
                  transform: var(--bs-accordion-btn-icon-transform);
                }
              }
            }
          }
        }
      }
    }
  }
}


.card-form {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;

  &.with-scroll {
    padding: calc(1.25rem - 5px);
  }

  @include media-breakpoint-up(md) {
    padding: 2.5rem;

    &.with-scroll {
      padding: calc(2.5rem - 5px);
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 3.75rem;

    &.with-scroll {
      padding: calc(3.75rem - 5px);
    }
  }
}
