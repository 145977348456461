.fw500 {
  font-weight: 500;
}

.fw800 {
  font-weight: 800;
}

.fs14px {
  font-size: 14px;
}

.fs12px {
  font-size: 14px;
}
