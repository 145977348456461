span.conversation-link {
  background-color: $grey4;
  border-radius: 3px;
  padding: 2px 3px;

  & > img {
    height: 1.25em;
    margin-right: 0.2em;
    margin-bottom: 2px;
    border-radius: 3px;
  }

  & > a {
    color: $blue;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

span.conversation-email {
  background-color: $grey4;
  border-radius: 3px;
  padding: 2px 3px;

  & > img {
    height: 1.25em;
    margin-right: 0.2em;
  }

  & > a {
    color: $blue;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
