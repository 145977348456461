.toast-container {
  padding: 15px 15px 15px 52px !important;

  .ngx-toastr {
    border-radius: 10px !important;
  }

  .toast-success {
    background-color: #0caaeb !important;
  }

  .toast-error {
    background-color: #f12828 !important;
  }
}
