$grey1: #202020;
$grey2: #707070;
$grey3: #afafaf;
$grey4: #ebebeb;
$grey5: #f4f4f4;
$grey6: #fafafa;

$blue: #5f78ff;
$blue2: #5f78ff;
$blue3: #5f78ff;
$blue4: #233cc3;
$blue5: #3a499b;
$blue10: #e7f7fd;

$blue-transparent: rgba(95, 120, 255, 0.08);
$blue-transparent1: rgba(95, 120, 255, 0.15);
$blue-transparent2: rgba(95, 120, 255, 0.05);
$blue-transparent3: rgba(95, 120, 255, 0.5);

$valid: #77e38f;
$red: #f12828;
$red2: #ba2a2a;
$orange: #e67e22;
$orange-alpha: rgba(230, 126, 34, 0.20);
$green: #1ca972;
$green-opacity70: rgba(28, 169, 114, 0.5);
$green-alpha: rgba(28, 169, 114, 0.20);
$green-light: #d8eee3;
$green-medium: #b4dcc9;
$green-medium-opacity70: rgba(180, 220, 201, 0.5);
$green2: #0e7f52;
$purple: #c090fb;
$yellow: #e8c901;
$pink: #ff00cb;
$pink2: #ae2e93;

$red-transparent: rgba(241, 40, 40, 0.08);

$gradient-blue: linear-gradient(45deg, $blue2 0%, $blue3 100%);
$gradient-blue-transparent: linear-gradient(
  45deg,
  transparentize($blue2, 0.4) 0%,
  transparentize($blue3, 0.4) 100%
);

.grey1 {
  color: $grey1;
}

.grey2 {
  color: $grey2;
}
.grey3 {
  color: $grey3;
}
.grey4 {
  color: $grey4;
}
.grey5 {
  color: $grey5;
}
.grey6 {
  color: $grey6;
}

.error {
  color: $red;
}
.valid {
  color: $valid;
}

.red {
  color: $red2;
}
.blue {
  color: $blue;
}
.orange {
  color: $orange;
}
.green {
  color: $green;
}
.purple {
  color: $purple;
}
.yellow {
  color: $yellow;
}
.pink {
  color: $pink;
}

.man {
  background-color: $blue4 !important;
}

.woman {
  background-color: $pink2 !important;
}

.nb {
  background-color: $green2 !important;
}
