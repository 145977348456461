/* You can add global styles to this file, and also import other style files */

// regular style toast
@import '~ngx-toastr/toastr';

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

/* Importing Dragula CSS file. */
@import "~dragula/dist/dragula.css";

@import url("./assets/fonts/style.css");

@import "./stylesheets/colors.scss";
@import "./stylesheets/cards.scss";
@import "./stylesheets/fonts.scss";
@import "./stylesheets/positions.scss";
@import "./stylesheets/toast.scss";
@import "./stylesheets/modal.scss";
@import "./stylesheets/inputs.scss";
@import "./stylesheets/bootstrap-override.scss";
@import "stylesheets/conversation";
@import "stylesheets/dropdown";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

body {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  touch-action: manipulation;

  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

  @include media-breakpoint-down(md) {
    height: 100dvh;

    .desktop {
      display: none !important;
    }
    .mobile {
      display: block !important;
    }
  }

  .ck-body-wrapper {
    z-index: 10000;
    position: relative;
  }

  .ck-table-resized {
    width: 100%;
  }
}

* {
  font-family: 'Nunito', sans-serif;
}

.tooltip-template-description {

  &.show {
    opacity: 1;

    &.alpha {
      opacity: 0.8;
    }
  }

  &.tooltip-bottom {
    & > .tooltip-arrow:before {
      border-bottom-color: $blue;
    }
  }

  &.tooltip-top {
    & > .tooltip-arrow:before {
      border-top-color: $blue;
    }
  }

  &.tooltip-start {
    & > .tooltip-arrow:before {
      border-left-color: $blue;
    }
  }

  &.tooltip-end {
    & > .tooltip-arrow:before {
      border-right-color: $blue;
    }
  }

  & > .tooltip-inner {
    background-color: $blue;
    color: white;
    text-align: start;
    max-width: 40vw;
  }
}

.tooltip-nutrition-goal-description {
  &.show {
    opacity: 0.9;

    &.alpha {
      opacity: 0.8;
    }
  }

  &.tooltip-bottom {
    & > .tooltip-arrow:before {
      border-bottom-color: $green2;
    }
  }

  &.tooltip-top {
    & > .tooltip-arrow:before {
      border-top-color: $green2;
    }
  }

  &.tooltip-start {
    & > .tooltip-arrow:before {
      border-left-color: $green2;
    }
  }

  &.tooltip-end {
    & > .tooltip-arrow:before {
      border-right-color: $green2;
    }
  }

  & > .tooltip-inner {
    background-color: $green2;
    color: white;
    text-align: start;
    max-width: 40vw;
  }
}

.tooltip-nutrition-nap-calculator {
  pointer-events: all !important;
  left: -50px !important;

  &.show {
    opacity: 1;
  }

  &.tooltip-bottom {
    & > .tooltip-arrow:before {
      border-bottom-color: $green;
    }
  }

  &.tooltip-end {
    & > .tooltip-arrow:before {
      border-right-color: $green;
    }
  }

  &.tooltip-start {
    & > .tooltip-arrow:before {
      border-left-color: $green;
    }
  }

  & > .tooltip-inner {
    background-color: $green-light;
    border: 1px solid $green;
    color: white;
    text-align: start;
    max-width: 40vw;
    max-height: 200px;
    overflow: scroll;
  }
}

.mr5px {
  margin-right: 5px !important;
}

.ml5px {
  margin-left: 5px !important;
}

.mr10px {
  margin-right: 10px !important;
}

.ml10px {
  margin-left: 10px !important;
}

.mt2px {
  margin-top: 2px !important;
}

.mt8px {
  margin-top: 8px !important;
}

.mt16px {
  margin-top: 16px !important;
}

.mb10px {
  margin-bottom: 10px !important;
}

.mb5px {
  margin-bottom: 5px !important;
}

.mb2px {
  margin-bottom: 2px !important;
}

.mt2em {
  margin-top: 2em !important;
}

bs-dropdown-container {
  z-index: 1055 !important;
}

.tooltip-msd {

}

.nap-slider {
  width: 460px;

  .ngx-slider {
    margin: 25px 0 15px;
  }

  .ngx-slider .ngx-slider-bar {
    background: $green-medium;
  }

  .ngx-slider[disabled] .ngx-slider-bar {
    background-color: $green-medium-opacity70;
  }

  .ngx-slider .ngx-slider-selection {
    background: $green2;
  }

  .ngx-slider .ngx-slider-pointer {
    width: 20px;
    height: 20px;
    top: -8px;
    background-color: $green;
    border-radius: 10px;
  }

  .ngx-slider[disabled] .ngx-slider-pointer {
    background-color: $green-opacity70;
  }

  .ngx-slider .ngx-slider-pointer:after {
    width: 6px;
    height: 6px;
    top: 7px;
    left: 7px;
  }

  .ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
    background-color: white;
  }

  .ngx-slider .ngx-slider-bubble {
    bottom: 10px;
    font-size: 12px;
    font-weight: 400;
  }

  .ngx-slider .ngx-slider-model-value {
    font-weight: 700;
  }

  .ngx-slider .ngx-slider-model-value:after {
    content: "H";
    font-weight: 700;
  }

  .ngx-slider .ngx-slider-limit {
    font-weight: bold;
    color: $green2;
  }

  .ngx-slider .ngx-slider-tick {
    background: $green-medium;
    margin-left: 5px;
  }

  .ngx-slider[disabled] .ngx-slider-tick {
    background: $green-medium-opacity70;
  }

  .ngx-slider .ngx-slider-tick.ngx-slider-selected {
    background: $green2;
  }
}

.nap-label {
  color: $grey2;
  font-weight: 700;
  font-size: 10px;
  text-transform: uppercase;
  margin-right: 5px;
}

.nap-indicator {
  position: fixed;
  top: 3px;
  right: 40px;
  border-radius: 4px;
  background-color: white;
  color: $green2;
  z-index: 100;
  border: 1px solid $green;
  padding: 2px 5px;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;

  &.warning {
    color: $orange;
  }

  &.danger {
    color: $red;
  }
}

.nap-separator {
  height: 20px;
}

.nap-button-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 5px;
}
