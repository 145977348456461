.text-primary {
  color: $blue !important;
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
.input::placeholder {
  opacity: 0.3 !important;
}

textarea {
  border: 1px solid $grey5 !important;
  border-radius: 10px !important;
  color: $grey1 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
