@import 'colors.scss';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

/*
$dropdown-link-hover-bg: $grey5;
$dropdown-border-radius: 0.5rem;
$dropdown-padding-x: 0;
$dropdown-padding-y: 0;
$dropdown-item-padding: 0.5rem 0.625rem;
$dropdown-divider-margin-y: 0; */

.dropdown-context-menu {
  .dropdown-menu {
    padding: 0.625rem 0 0 0;
    border: 1px solid $grey4;
    border-radius: 0.5rem;

    &.dropstart {
      top: 0 !important;
      right: 100% !important;
      left: auto !important;
      margin-top: 0 !important;
      margin-right: 0.125rem !important;
    }

    @include media-breakpoint-down(lg) {
      &.dropstart {
        top: -30px !important;
      }
      max-height: 100px !important;
      overflow: auto !important;
    }
  }

  .dropdown-item {
    margin: 0 0.625rem;
    padding: 0.5rem 0.625rem;
    border-radius: 0.625rem;
    font-weight: 600;
    font-size: 13px;
    color: $grey1;
    width: auto;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      background-color: $grey5;
    }

    &.disabled {
      opacity: 50%;
      cursor: default !important;
    }

    &.destructive-item {
      color: $red;
    }
  }

  .dropdown-divider {
    border-top: 1px solid $grey4;
  }

  .dropdown-item.action {
    margin: 0;
    text-align: center;
    font-weight: bold;
    color: $blue;
    background-color: transparent;
  }

  #cancel-link {
    transition: all 0.3s ease;

    &:hover {
      color: transparentize($color: $blue, $amount: 0.7);
    }
  }
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: white;
  visibility: hidden;
  transition: all 0.2s ease;
}

.dropdown.show {
  .dropdown-backdrop {
    visibility: visible;
    opacity: 0.7;
  }
}

.dropup {
  .dropdown-menu[data-bs-popper] {
    top: auto !important;
    bottom: 100% !important;
    margin-top: 0;
    margin-bottom: 3px;
  }
}

